import Link from "next/link";

export default function RelatedCard({ businessUrl,businessName,ratingCount,rating,reviewCount,businessImage,businessLocation }) {
  return (
    <Link href={businessUrl} className="max-w-xs rounded-xl overflow-hidden shadow-md bg-card text-card-foreground transition-transform transform hover:scale-105 w-full">
      <div className="">
        <img
          className="w-full h-48 object-cover"
          src={businessImage}
          alt="Electrician Van"
        />
        <div className="p-4">
          <h2 className="text-md font-bold mb-1">{businessName}</h2>
          <div className="flex flex-wrap gap-4 mt-2">
            <p className="text-gray-600">{ratingCount} reviews</p>
            {rating && (
              <div className="flex items-center space-x-1 text-yellow-500">
                {[...Array(Math.floor(rating))].map((_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-3 h-3"
                  >
                    <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.877 1.516 8.25L12 18.9l-7.452 4.396 1.516-8.25-6.064-5.877 8.332-1.151z" />
                  </svg>
                ))}
              </div>
            )}
            <p className="text-gray-600">({reviewCount})</p>
            {/* <p className="flex items-center text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 mr-1"
              >
                <path d="M19.428 15.304a8.5 8.5 0 00-6.303-10.608 8.501 8.501 0 00-10.607 6.303 8.502 8.502 0 006.304 10.608 8.503 8.503 0 0010.606-6.303z" />
                <path d="M12 7v5l3 3" />
              </svg>
            </p> */}
          </div>
          <p className="text-muted-foreground flex items-center gap-3 text-sm mt-1">
            <span><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602"/></svg></span>
            {businessLocation}
          </p>
        </div>
      </div>
    </Link>
  );
}
