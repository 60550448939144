import Head from "next/head";
import React from "react";

const SeoData = () => {
  return (
    <Head>
        <title>My Awesome Website</title>
        <meta name="description" content="A great website about something." />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />

        <meta property="og:title" content="My Awesome Website" />
        <meta
          property="og:description"
          content="A great website about something."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="My Awesome Website" />
        <meta
          name="twitter:description"
          content="A great website about something."
        />
        <meta name="twitter:image" content="https://example.com/image.jpg" />

        <link rel="canonical" href="https://example.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
};

export default SeoData;
