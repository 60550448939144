"use client";
import {fetchStateData} from "@/utils/fetchFromMongo";
import Link from "next/link";
import { useSearchParams, usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
import HeaderSection from "../components/hero-section/headerSection";
import SearchBar from "../components/hero-section/searchBar";
import SingleCard from "../components/cards/singleCard";
import Navbar from "../components/navbar";
import {
  capitalizeFirstLetter,
  capitalizeHyphenatedString,
  sortByReviewAndRating,
} from "@/utils/common";
import { Box, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Head from "next/head";
import SeoData from "@/utils/seodata";
import RelatedCard from "../components/cards/relatedCard";


const LocationIndex = ({ params,serverData, cityServerData }) => {
  const [data, setData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const pathname = usePathname();
  const segments = pathname.split("/").filter(Boolean);
  const stateUrl = segments[0].toLowerCase().replace(/\s+/g, "-"); // The first segment after the domain
  const stateInput = capitalizeHyphenatedString(stateUrl);
  const location = capitalizeFirstLetter(stateInput);
  // Fetch data on initial load
  useEffect(() => {
    const fetchList = async () => {
      try {
        const res = serverData;
        if (res) {
          setData(res);
        }
      } catch (error) {
        console.log(error, "error in fetching data");
      }
    };
    fetchList();
  }, [serverData]);
  const fetchAreaList = async () => {
    try {
      const res = cityServerData;
      if (res) {
        setCityData(res);
      }
    } catch (error) {
      console.log(error, "error in fetching data");
    }
  };


  // Filter and sort the data based on location
  useEffect(() => {
    if (location && data) {
      const filtered = data.filter(
        (listing) =>
          location === listing?.state ||
          listing?.full_address?.includes(location)
      );

      // Sorting logic (sort by rating first, then by review_count)
      const sortedData = sortByReviewAndRating(filtered)

      setFilteredData(sortedData);
    }
  }, [location, data]);

  // Handle search input
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterData(e.target.value);
  };

  // Filter search results
  const filterData = (term) => {
    const filteredResults = filteredData.filter(
      (item) =>
        item.name.toLowerCase().includes(term.toLowerCase()) ||
        item.full_address.toLowerCase().includes(term.toLowerCase()) ||
        (item.zipcode && item.zipcode.toString().includes(term))
    );
    setSearchResults(filteredResults);
  };

  // Re-filter when filtered data changes
  useEffect(() => {
    filterData(searchTerm);
    fetchAreaList()
    console.log(cityServerData, 'city ka server data check karo')
  }, [filteredData, cityData]);

  return (
    <>
      <SeoData/>
    <div className="w-screen">
      <Navbar />
      <div className="w-full">
        <HeaderSection
          header={`Best Gift Shops in ${location}`}
          paragraph={`Explore the best gift shops in ${location} with Gift Shops Finder. Discover unique boutiques, local stores, and specialty shops across the state to find the perfect gift for any occasion. Start browsing gift shops in ${location} now!`}
          breadCrumbUrl="/"
          breadCrumbText={`Gift Shops near me`}
          breadCrumbAfter={location}
          breadCrumbAfterURL={" "}
          // phoneNumber="888-7779-965"
        />
      </div>
      <div className="w-full">
        <SearchBar
          searchHeader="Find Best Gift Shops Near Me"
          placeholder="Enter Your Address or ZIP Code"
          onChange={handleSearch}
          value={searchTerm}
        />
      </div>
      <div className="mt-4">
        <section className="w-full md:p-0 container mx-auto">
          <div className="max-w-[1324px] md:pt-0 pt-10 mx-auto flex justify-center items-center pb-16 lg:flex-col lg:pb-10">
            <div className="w-full max-w-[1032px] md:py-0 md:px-0 px-5 pb-20 md:pb-8">

              {/* Display Header */}
              {searchResults && searchResults.length > 0 && (
                <div className="text-left my-4">
                  <p className="text-2xl font-bold text-gray-800">
                    {searchResults.length} Best Gift Shops in {location}
                  </p>
                </div>
              )}

              {/* Map over cityData to display city names and listings */}
              {cityData && Object.entries(cityData).length > 0 ? (
                Object.entries(cityData).map(([city, listings], index) => (
                  <div key={index}>
                    {/* City Name as Title */}
                    <div className="flex justify-between w-full">
                      <h3 className="text-xl font-bold">{city} ({listings.length})</h3>
                      <Link className="pt-3 underline text-blue-500" href={`${listings[0].areas.toLowerCase().replace(/\s+/g, '-')}`}>
                        View all
                      </Link>
                    </div>

                    {/* Map over listings for each city */}
                    <div className="my-3 mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:mx-auto">
                      {listings.slice(0, 3).map((item, index) => {
                        const slug = item.name
                          ? item.name.toLowerCase()
                              .replace(/[^a-z0-9\s-]/g, "")
                              .replace(/\s+/g, "-")
                              .replace(/-+/g, "-")
                              .replace(/^-|-$/g, "") + `-${item.unique_id}`
                          : "";

                        return (
                          <RelatedCard
                            key={index}
                            businessUrl={item.singleData ? item.singleData : `/listing/${slug}`}
                            businessName={item.name}
                            ratingCount={item.review_count}
                            rating={item.rating}
                            reviewCount={item.review_count}
                            businessLocation={item.full_address}
                            businessImage={item.photos_sample ? item.photos_sample : "/assets/imgs/no-image.jpg"}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))
              ) : (
                <p>No data available for listing</p>
              )}

            </div>
          </div>
        </section>
      </div>

    </div>
    </>
  );
};

export default LocationIndex;